/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-28 21:53:44
 * @LastEditors: hutian
 * @LastEditTime: 2021-04-20 10:39:22
 */
/**
 * api接口统一管理
 */
import { axios } from '@/utils/request'

export const listLimitInfo = params => axios({
  url: '/api/dealer/limit/limitInfo/listDealer',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const listLimitInfoOverdue = params => axios({
  url: '/api/dealer/limit/limitInfo/listOverdue',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const addLimitInfo = params => axios({
  url: '/api/dealer/limit/limitInfo/add',
  method: 'post',
  data: JSON.stringify(params),
  headers: {

    'Content-Type': 'application/json;charset=UTF-8'
  }
})

export const editLimitInfo = params => axios({
  url: '/api/dealer/limit/limitInfo/edit',
  method: 'post',
  data: JSON.stringify(params),
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

export const delLimitInfo = params => axios({
  url:'/api/dealer/limit/limitInfo/del/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const selectByIdLimitInfo = params => axios({
  url: '/api/dealer/limit/limitInfo/selectById/' + params,
  method: 'get',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})


export const downExcel = params => axios({
  url: '/api/customer/customer/financeReceipt/excel',
  method: 'post',
  responseType: 'blob',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const listLog = params => axios({
  url: '/api/dealer/limit/limitInfo/logList',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const rollbackStatus = params => axios({
  url:'/api/dealer/limit/limitInfo/rollbackStatus/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const returnStatus = params => axios({
  url:'/api/dealer/limit/limitInfo/returnStatus/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const listDealerLimit = params => axios({
  url: '/api/dealer/dealer/dealerInfo/limitList',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const repaymentAll = params => axios({
  url:'/api/dealer/limit/limitInfo/repaymentAll/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})



